import {
  mdiViewDashboard, mdiAccount, mdiAccountGroup, mdiCalendarText, mdiHeart,
  mdiPackage, mdiForum, mdiTagHeart, mdiTicketAccount, mdiFileDocument, mdiApps,
} from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiViewDashboard,
    to: 'home',
  },
  {
    subheader: 'Manajemen',
  },
  {
    title: 'Pelanggan',
    icon: mdiHeart,
    to: 'customers',
  },
  {
    title: 'Produk',
    icon: mdiPackage,
    to: 'products',
  },
  {
    title: 'Percakapan',
    icon: mdiForum,
    to: 'chats',
  },
  {
    title: 'Prospek',
    icon: mdiTagHeart,
    to: 'prospects',
  },
  {
    title: 'Tiket & Keluhan',
    icon: mdiTicketAccount,
    to: 'tickets',
  },
  {
    title: 'Laporan',
    icon: mdiFileDocument,
    to: 'reports',
  },
  {
    subheader: 'Personal',
  },
  {
    title: 'Aktivitas',
    icon: mdiCalendarText,
    to: 'activities',
  },
  {
    subheader: 'Administrator',
  },
  {
    title: 'Pengguna',
    icon: mdiAccount,
    to: 'users',
  },
  {
    title: 'Tim',
    icon: mdiAccountGroup,
    to: 'teams',
  },
  {
    title: 'Integrasi',
    icon: mdiApps,
    to: 'integrations',
  },
]
