import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/chats',
    name: 'chats',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/prospects',
    name: 'prospects',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/activities',
    name: 'activities',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/teams',
    name: 'teams',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/integrations',
    name: 'integrations',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
